import CodeExample from "components/docs/code-examples/CodeExample";
import { Link } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import DocumentationSection from "../../components/docs/DocumentationSection";
import HeaderBar from "../../components/docs/layout/HeaderBar";
import CrossGuideLinks from "../../components/docs/navigation/CrossGuideLinks";
import DocsHelmet from "../../components/docs/shared-components/DocsHelmet";
import { HTTPMethodBadge } from "../../components/docs/shared-components/MergeBadges";
import { StaticPageContainer } from "../../components/docs/shared-components/PageContainers";
import { HTTPMethod, RouterLocation } from "../../types/types";
import { PricingInfoCard } from "components/docs/shared-components/NoticeCards";
import LINKS from "components/docs/navigation/links";

const HTTPMethodBadgeWithStyle = styled(HTTPMethodBadge)`
  font-size: 16px;
  line-height: 26px;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
`;

interface Props {
  location: RouterLocation;
}
function AsyncOperationPage({ location }: Props) {
  const title = "Async Operations";
  const description = "Learn how to make asynchronous POST requests";
  return (
    <StaticPageContainer style={{ paddingBottom: "0" }}>
      <DocsHelmet title={title} description={description} />
      <HeaderBar title={title} subtitle={description}>
        <PricingInfoCard className="mb-9 mt-0">
          Asynchronous POST requests are currently only supported in Merge’s{" "}
          <a href={LINKS.ACCOUNTING_OVERVIEW.linkTo} target="_blank" rel="noreferrer">
            Accounting
          </a>{" "}
          category. This feature is currently in beta, reach out to your account representative or{" "}
          <a href="mailto: support@merge.dev">contact us</a> for more information.
        </PricingInfoCard>
      </HeaderBar>
      <DocumentationSection title="Overview">
        <>
          <p>
            Asynchronous operations are useful for efficiency and to avoid timeouts for long running
            requests. To make an asynchronous request, append{" "}
            <span className=" font-semibold">/async</span> at the end of the respective synchronous
            POST endpoint. For asynchronous requests, the server returns a <code>task_id</code> , a
            unique identifier used to track the status of the async operation.
          </p>
          <CodeExample
            codeBlockName="Example response"
            folder="basics/async-operations"
            colorScheme="light"
            hideCopyButtonWithHeader
            hasLineNumbers={false}
          />
          <p>
            To check the progress and get the final result, send a request to the task polling
            endpoint:{" "}
            <a href={"/accounting/async-tasks/"} target="_blank" rel="noreferrer">
              <span className=" font-semibold">GET</span>{" "}
              https://api.merge.dev/api/accounting/v1/async-tasks/&#123;task_id&#125;
            </a>
            .
          </p>
        </>
      </DocumentationSection>

      <CrossGuideLinks location={location} style={{ marginTop: "12px" }} />
    </StaticPageContainer>
  );
}

export default AsyncOperationPage;
